<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Manifiestos creados
                        <v-spacer></v-spacer>
                        
                            <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        <div class="mx-0">
                            <v-btn  color="primary" fab x-small dark v-show="selected.length == 1">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between mb-4">
                                    <v-text-field
                                    v-model="search"
                                    clearable
                                    label="Buscar"
                                    hide-details
                                    dense
                                    solo
                                    class="mr-2"
                                    ></v-text-field>
                                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchData" >Buscar</v-btn>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex flex-column">
                                    <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                                    <v-menu
                                        ref="menu1" v-model="menu1" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px"
                                        min-width="290px" style="border-bottom: 1px solid #000;"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                            @click:clear="limpiarRango"
                                            v-model="dateRangeText"
                                            placeholder="Todos"
                                            solo
                                            readonly
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                                    </v-menu>
                                    </div>
                                    <div class="d-flex flex-column">
                                    <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_states"></i> Filtro por estado</span>
                                    <v-select
                                        class="ml-2 mr-2"
                                        label="Filtro por courier"
                                        :items="carrierList"
                                        v-model="selectCourier"
                                        item-text="text"
                                        @input="fetchData"
                                        solo
                                        ref="select_carrier"
                                    ></v-select>
                                    </div>
                                    
                                    <div class="d-flex">
                                    <v-btn
                                        v-show="!!search || !!dateRangeText || selectOrderState != 'Todos' || selectTypeList != 'Todos'"
                                        @click="limpiarFiltros"
                                        class="align-self-center" dark color="grey"
                                    >Limpiar Filtros</v-btn>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            :sort-by="'id'"
                            :sort-desc="true"
                            :search="search"
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.estado="{ item }">
                                <td class="text-center">
                                    <!--
                                    <b-button size="sm" v-if="item.name_order_state == 'Ingresado'" pill variant="success">Ingresado</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Preparando'" pill variant="info">Preparando</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Listo para enviar'" pill variant="info">Listo para enviar</b-button>                               
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado a courier'" pill variant="info">Entregado a courier</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'En tránsito'" pill variant="info">En tránsito</b-button>
                                    <b-button size="sm" v-if="item.name_order_state == 'Entregado'" pill variant="info">Entregado</b-button>
                                    -->
                                </td>
                            </template>
                            <template v-slot:item.acciones="{ item }">
                                <td class="d-flex flex-column">
                                    <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'envio', params: {envio: item.id}})"
                                            >
                                                mdi-arrow-right-box
                                            </v-icon>
                                        </template>
                                        <span>Ver detalles</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                class="mr-2"
                                                v-on="on"
                                                @click="$router.push({name: 'orden por ref', params: {ref: item.order_reference}})"
                                            >
                                                mdi-cart-arrow-right
                                            </v-icon>
                                        </template>
                                        <span>Ver orden</span>
                                    </v-tooltip> -->
                                    <v-btn
                                    small
                                    color="#57C600" dark
                                    :href=item.pdf
                                    target="_blank"
                                    >
                                    <v-icon left dark>mdi-file-document</v-icon>
                                    Ver PDF
                                </v-btn>
                                </td>

                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
                        </div>

                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data () {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            carrierList: [],
            selectCarrier: undefined,
            search: '',
            selected: [],
            
            tableData: [],
            dates: [],
            menu1: false,
            dateRangeText: '',

            selected: [],
            couriersList: [],
            selectCourier: undefined
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Manifiestos", route: "manifiestos" },
        ]);
        this.fetchCarrierList();
        this.fetchData();
        this.fetchCouriers();
    },
    methods: {
        fetchCarrierList()
        {
            var vm = this;
            this.axios({
                url: 'shippings/carriers/get_data_list',
                method: 'GET'
            }).then(response => {
                vm.carrierList = response.data;
                vm.carrierList.push({text: 'Todos', value: 0});
            }).catch(error => {
                console.log(error);
            })
        },
        limpiarFiltros() {
            this.search = "";
            this.dateRangeText = "";
            this.selectCourier = "Todos";
            this.fetchData();
        },
        fetchData()
        {
            var vm = this;
            this.axios({
                url: 'shippings/manifests/json', 
                method: 'GET',
                params: {
                    "pagination[page]": "1",
                    "pagination[pages]": "24",
                    "pagination[perpage]": "10",
                    "pagination[total]": "234",
                    "sort[sort]": "asc",
                    "sort[field]": "id",
                    "query": ""
                }
            }).then( response => {
                /**Pagination */
                vm.pageCount = Math.ceil(response.data.data.length / 10);

                vm.tableData = response.data.data;
            }).catch( error => {
                console.log(error);
            })
        },
        filtroRangoFechas(value){
            if( !this.dateRangeText || !this.dates.length ){
                return true;
            }else{
                if( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1])) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        verRefSelect()
        {
            console.log('select change',this.$refs.select_carrier.selectedItems);
        },
        filtroCarrier(v)
        {   
            if( this.$refs.select_carrier.selectedItems[0] ){
                if(this.$refs.select_carrier.selectedItems[0].text == 'Todos') return true;
                return this.$refs.select_carrier.selectedItems[0].text == v
            } else {
                return true;
            }
        },
        formatearRango()
        {
            if(!this.dates.length){
                this.dateRangeText = '';
            }else{
                this.dateRangeText = this.dates.join(' ~ ');
            }
        },
        limpiarRango()
        {
            this.dates = [];
        },
        fetchCouriers(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'shippings/couriers/data_select_input/'+'1'
            }).then( response => {
                vm.couriersList = response.data;
            }).catch( error => {
                console.log(error);
            });
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '#...',align: 'left', sortable: true,value: 'id'},
                { text: 'Fecha', value: 'updated_at', filter: v => this['filtroRangoFechas'](v) },
                { text: 'Courier', value: 'carrier_name', filter: v => this['filtroCarrier'](v) },
                { text: 'Actions', value: 'acciones', sortable: false },
            ];
        }
    }
}
</script>